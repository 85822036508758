import {
	createPageSegmentLoadMetric,
	INLINE_COMMENTS_HIGHLIGHTS_SEGMENT_KEY,
	FOCUSED_INLINE_COMMENT_SEGMENT_KEY,
	RENDERER_INLINE_COMMENT_RENDER_SEGMENT_KEY,
} from '@confluence/browser-metrics';

// Renderer Inline Comments
export const INLINE_COMMENTS_HIGHLIGHTS_METRIC = createPageSegmentLoadMetric({
	key: INLINE_COMMENTS_HIGHLIGHTS_SEGMENT_KEY,
	timings: [
		{
			key: 'fmp',
			endMark: 'fmp',
		},
		{
			key: 'tti',
			endMark: 'tti',
		},
		{
			key: 'highlightsRendered',
			endMark: 'highlightsRendered',
		},
	],
});

export const FOCUSED_INLINE_COMMENT_METRIC = createPageSegmentLoadMetric({
	key: FOCUSED_INLINE_COMMENT_SEGMENT_KEY,
});

export const RENDERER_INLINE_COMMENT_RENDER_METRIC = createPageSegmentLoadMetric({
	key: RENDERER_INLINE_COMMENT_RENDER_SEGMENT_KEY,
});
